exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archivo-de-noticias-js": () => import("./../../../src/pages/archivo-de-noticias.js" /* webpackChunkName: "component---src-pages-archivo-de-noticias-js" */),
  "component---src-pages-archivo-de-paginas-js": () => import("./../../../src/pages/archivo-de-paginas.js" /* webpackChunkName: "component---src-pages-archivo-de-paginas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-wp-js": () => import("./../../../src/templates/page-wp.js" /* webpackChunkName: "component---src-templates-page-wp-js" */),
  "component---src-templates-post-wp-js": () => import("./../../../src/templates/post-wp.js" /* webpackChunkName: "component---src-templates-post-wp-js" */)
}

