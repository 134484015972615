import { darken } from "@theme-ui/color"

const theme = {
  space: [
    0,
    `0.375rem`,
    `0.75rem`,
    `1.50rem`,
    `3rem`,
    `3.75rem`,
    `4.50rem`,
    `5.25rem`,
    `6rem`,
    `6.75rem`,
    `7.50rem`,
  ],
  fonts: {
    body: `system-ui, sans-serif`,
    heading: `system-ui, sans-serif`,
    monospace: `monospace`,
  },
  fontSizes: {
    "0": `0.694rem`,
    "1": `0.833rem`,
    "2": `1rem`,
    "3": `1.2rem`,
    "4": `1.44rem`,
    "5": `1.728rem`,
    "6": `2.074rem`,
    "7": `2.488rem`,
    "8": `2.986rem`,
    "9":`4.3rem`,
    "10": `5.16rem`,
    "11": `6.192rem`,
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  letterSpacings: [
    0,
    `0.5px`,
    `1px`,
  ],
  lineHeights: {
    body: 1.5,
    heading: 1.1,
    none: .88,
    snug: 1.35,
    relaxed: 1.75,
  },
  colors: {
    text: `#2A3131`,
    background: `#FFFFFF`,
    primary: `#4599A0`,
    secondary: `#DD9933`,
    tertiary: `#FF0000`,
    muted: `#BFBFBF`,
    white: `#FFFFFF`,
    black: `#000000`,
    /*
     * Grayscale from WordPress site
     * $gray-darker:               #0C0D0D
     * $gray-dark:                 #262626
     * $gray:                      #727373
     * $gray-light:                #BFBFBF
     * $gray-lighter:              #D8D9D9
     * $gray-lightest:             #F2F2F2
    */
    gray: [`#F2F2F2`, `#D8D9D9`, `#BFBFBF`, `#727373`, `#262626`, `#0C0D0D`],
  },
  breakpoints: [
    `640px`,
    `769px`,
    `1025px`,
    `1281px`,
    `1640px`,
  ],
  sizes: {
    px: `1px`,
    "0": `0`,
    "1": `0.187rem`,
    "2": `0.375rem`,
    "3": `0.75rem`,
    "4": `1.50rem`,
    "5": `2.25rem`,
    "6": `3rem`,
    "8": `3.75rem`,
    "10": `4.50rem`,
    "12": `5.25rem`,
    "16": `6rem`,
    "20": `6.75rem`,
    "24": `7.50rem`,
    "32": `8.25rem`,
    "40": `9rem`,
    full: `100%`,
    screenHeight: `100vh`,
    screenWidth: `100vw`,
    phoneMaxWidht: `87.5vw`,
    tabletMaxWidth: `75vw`,
    desktopMaxWidth: `50vw`,
    desktopLargeMaxWidth: `50vw`,
  },
  forms: {
    input: {
      borderRadius: 0,
    },
    textarea: {
      borderRadius: 0,
    }
  },
  buttons: {
    primary: {
      color: `text`,
      border: theme => `1px solid ${theme.colors.text}`,
      borderRadius: 0,
      backgroundColor: `transparent`,
      cursor: `pointer`,
      fontSize: 1,
      fontWeight: `bold`,
      minHeight: 8,
      padding: 2,
      ":hover": {
        backgroundColor: darken(`primary`, 0.15),
        a: {
          borderBottom: `none`,
          color: `white`
        }
      },
      a: {
        borderBottom: `none`,
        color: `text`,
      }
    },
    secondary: {
      color: `white`,
      border: `none`,
      borderRadius: 0,
      backgroundColor: `primary`,
      cursor: `pointer`,
      fontSize: 1,
      fontWeight: `bold`,
      padding: 2,
      ":hover": {
        backgroundColor: darken(`primary`, 0.15),
        a: {
          borderBottom: `none`,
          color: `white`
        }
      },
      a: {
        color: `white`,
        borderBottom: `none`,
      }
    },
  },
  layout: {
  // Viene de breakpoint theme-ui
  // Container tiene 6 valores
  // Estan los 5 valores de breakpoints + uno por defecto (el primero)
  // Por lo tanto puedo declarar:
  // maxWidth: [`100%`, `50%`, `45%`, `30%`, `23%`, `15%`],
  // Donde:
  // minWidth 100%
  // minWidth 640px 50% 
  // minWidth 769px 45%
  // minWidth 1025px 30%
  // minWidth 1281px 23%
  // minWidth 1640px 15%
    container: {
      maxWidth: [`phoneMaxWidht`, `phoneMaxWidht`, `tabletMaxWidth`, `desktopMaxWidth`, `desktopMaxWidth`, `desktopLargeMaxWidth`],
    },
  },
  text: {
    heading: {
      color: `text`,
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
    },
    base: {
      color: `text`,
      fontFamily: `body`,
      fontSize: [2, 2, 2, 3, 3, 4],
      fontWeight: `body`,
      lineHeight: `snug`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
  },
  styles: {
    root: {
      boxSizing: `border-box`,
      fontFamily: `body`,
      fontWeight: `body`,
      padding: 0,
      position: `relative`,
      ".tl-edges": {
        overflowY: `hidden`,
      },
    },
    h1: {
      variant: `text.heading`,
      fontSize: [3, 3, 3, 6, 6, 6],
      marginTop: 1,
      marginBottom: [2, 2, 2, 3, 4, 4],
    },
    h2: {
      variant: `text.heading`,
      fontSize: [2, 2, 2, 4, 4, 4],
      marginTop: [2, 2, 2, 3, 4, 4],
      marginBottom: 1,
    },
    h3: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 3, 3, 3],
      marginTop: [2, 2, 2, 3, 4, 4],
      marginBottom: 1,
    },
    h4: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 2, 2, 2],
    },
    h5: {
      variant: `text.heading`,
      fontSize: 1,
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
    },
    p: {
      variant: `text.base`,
      marginTop: 0,
      marginBottom: [3, 3, 3, 3, 3, 3],
    },
    a: {
      borderBottom: `1px dotted`,
      color: darken(`primary`, 0.1),
      cursor: `pointer`,
      fontFamily: `body`,
      textDecoration: `none`,
      transition: `all 0.3s ease-in-out`,
      ":hover": {
        borderBottom: `1px solid`,
        color: darken(`primary`, 0.15),
      },
    },
    li: {
      variant: `text.base`,
    },
    img: {
      maxWidth: `100%`,
    },
    figure: {
      margin: 0,
      "ul": {
        listStyle: `none`,
        margin: 0,
        padding: 0,
      }
    }
  }

}

export default theme